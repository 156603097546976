<template>
      
	<!------------------------------Header------------------------------------>
	<q-header :class="'bg-'+state.color" elevated>
		<q-toolbar>
			<q-btn flat @click="leftDrawerOpen = !leftDrawerOpen" round dense icon="apps" />
			<q-btn flat round dense icon="home" href="/"/>
			<q-toolbar-title>
				<q-btn flat class="q-pl-xs" :href="'/'+state.device">
				{{screenWidth()!='small'?state.device!='home'?state.device:'Thailand':state.shortName}}
				{{screenWidth()!='small' && state.device!='Exchange' ? 'Price Chart': ''}}</q-btn>
			</q-toolbar-title>
			<div>
				<q-btn v-if="inter && false" flat round icon="settings" title="Setting">
					<q-menu class="q-pa-sm">
						<q-select v-model="store" 
							label="Store" :options="stores" 
							style="width:80px" dense
							@update:model-value="changeStore()"
						></q-select>
						<q-select v-model="currency" 
							label="Currency" :options="stores" 
							style="width:80px" dense
							@update:model-value="changeStore()"
						></q-select>
					</q-menu>
				</q-btn>
				
				<q-btn dense v-if="state.device=='xbox' || state.device=='appletv' || state.device=='steam'" flat round 
					icon="add" title="Add Content" @click="showAddXbox=true;message='';linkXbox='';price=''" />
				<q-btn v-if="state.device=='xbox' || state.device=='nintendo'" flat round icon="credit_card" title="Setting">
					<q-menu class="q-pa-sm">
						<q-toggle
							v-if="state.device=='nintendo' || state.device=='xbox'"
							v-model="showARS"
							color="blue"
							title="All Store"
							label="All Store"
						/><br v-if="state.device=='nintendo'|| state.device=='xbox'">
						<q-toggle
							v-model="cType"
							color="orange"
							title="Card Type"
							:label="cType?'Visa':'Master'"
						/>
						<q-input v-model="rate" style="width: 120px;" label="Bank Fee (%)"
							type="number"
							clearable 
							@clear="rate='0';">
						</q-input>
						<q-btn label="change" class="q-pa-xs q-mt-sm" color="orange" @click="state.changeRate(cType,rate,showARS)"></q-btn>
					</q-menu>
				</q-btn>
				<q-toggle
					v-model="view"
					checked-icon="description"
					color="black"
					unchecked-icon="image"
					@click="state.changeView()"
					title="View"
				/>
				<q-toggle
					dense
					v-model="theme"
					checked-icon="dark_mode"
					color="black"
					unchecked-icon="light_mode"
					@click="toggleDarkMode()"
					title="Theme"
					class="q-pr-sm"
				/>
				<q-btn v-if="state.user.uid==''" flat label="login" title="Login" @click="showLogin=true;username='';code=''" class="q-pa-xs" />
				<q-btn v-else flat round>
					<q-avatar>
						<img :src="state.user.picture">
					</q-avatar>
					<q-menu class="q-px-sm">
						<q-btn flat dense label="Setting" @click="openNotify()"/><br>
						<q-btn flat dense  @click="state.logout()">Logout</q-btn>
					</q-menu>
				</q-btn>
			</div>
		</q-toolbar>
	</q-header>

	<!------------------------------Left Menu------------------------------------>
	<q-drawer
        v-model="leftDrawerOpen"
		:breakpoint="1200"
        show-if-above
        :width="200"
        bordered
	>
		<div class="q-px-md q-pt-sm">
			<q-btn :icon-right="iconInfo" :label="'v. '+infoList[0].v" :color="state.color" dense flat @click="showInfo=true;"></q-btn>
			<q-btn icon="campaign" :color="state.color" dense size="lg" flat @click="showNews=true;"></q-btn>
		</div>
		<div class="q-px-sm">
			<q-btn flat round dense icon="mdi-facebook" :color="state.color" href="https://www.facebook.com/numcreator" target="_blank"></q-btn>
			<q-btn flat round dense icon="mdi-account-group" :color="state.color" href="https://www.facebook.com/groups/1505122043340465" target="_blank"></q-btn>
		</div>
		<!-- <q-list class="q-pt-md" dense>
			<q-item clickable v-ripple :to="'/'+state.device+'/library'" href="https://www.facebook.com/groups/1505122043340465" target="_blank">
				<q-item-section avatar>
					<q-icon name="library_books" />
				</q-item-section>
				<q-item-section>
					Group
				</q-item-section>
			</q-item>
		</q-list> -->

		<q-list v-if="state.user.uid!='' && state.device!='Exchange' && state.device!='home'" dense>
			<q-item>
				<q-item-section avatar>
					<q-icon name="library_books" />
				</q-item-section>
				<q-item-section>
					My Library
				</q-item-section>
			</q-item>
			<q-item>
				<q-item-section avatar>
				</q-item-section>
				<div class="q-gutter-x-sm">
					<q-btn :color="state.color" size="sm" label="1" @click="changeLib(1)" round dense :outline="loc.lib==1?false:true"></q-btn>
					<q-btn :color="state.color" size="sm" label="2" @click="changeLib(2)" round dense :outline="loc.lib==2?false:true"></q-btn>
				</div>
			</q-item>
		</q-list>

		<q-list dense v-if="state.device!='Exchange'">
			<q-item-label header>Menu</q-item-label>
			<template v-for="(menuItem, index) in menuList" :key="index">
				<q-item clickable v-ripple :to="'/'+state.device+menuItem.link">
					<q-item-section avatar>
					<q-icon :name="menuItem.icon" />
					</q-item-section>
					<q-item-section>
					{{ menuItem.label }}
					</q-item-section>
				</q-item>
			</template>

			
			<q-expansion-item
				v-if="loc.store=='th' && state.device=='playstation'"
				expand-separator
				icon="add"
				label="PS Plus"
				:content-inset-level="1"
				dense
			>
				<q-item clickable v-ripple to="/playstation/plus" dense>
					<q-item-section>All</q-item-section>
				</q-item>
				<q-item clickable v-ripple to="/playstation/essentials" dense>
					<q-item-section>Essentials</q-item-section>
				</q-item>
				<q-item clickable v-ripple to="/playstation/extra" dense>
					<q-item-section>Extra</q-item-section>
				</q-item>
				<q-item clickable v-ripple to="/playstation/deluxe" dense>
					<q-item-section>Deluxe</q-item-section>
				</q-item>
			</q-expansion-item>

			<q-expansion-item
				v-if="state.device=='nintendo'"
				expand-separator
				icon="mdi-ticket-percent-outline"
				label="Voucher"
				:content-inset-level="1"
				dense
			>
				<q-item clickable v-ripple to="/nintendo/voucher1" dense>
					<q-item-section>US</q-item-section>
				</q-item>
				<q-item clickable v-ripple to="/nintendo/voucher3" dense>
					<q-item-section>JP</q-item-section>
				</q-item>
			</q-expansion-item>

			<q-item v-if="loc.store=='th' && (state.device=='playstation' || state.device=='xbox' || state.device=='steam')" 
				clickable v-ripple :to="'/'+state.device+'/subthai'">
				<q-item-section avatar>
					<q-avatar size="sm"><b>TH</b></q-avatar>
				</q-item-section>
				<q-item-section>
					Thai Subtitle
				</q-item-section>
			</q-item>

			<q-item v-if="state.device=='steam'" 
				clickable v-ripple :to="'/'+state.device+'/modthai'">
				<q-item-section avatar>
					<q-icon size="sm" name="construction"></q-icon>
				</q-item-section>
				<q-item-section>
					Mod Thai
				</q-item-section>
			</q-item>
			
		</q-list>

		<q-list class="q-pt-sm" dense>
			<q-item-label header>Other</q-item-label>
			<template v-for="(menuItem, index) in menuOther" :key="index">
				<q-item v-if="'/'+state.device!=menuItem.link" clickable v-ripple :href="menuItem.link">
					<q-item-section avatar>
					<q-icon :color="menuItem.color" :name="menuItem.icon" />
					</q-item-section>
					<q-item-section>
					{{ menuItem.label }}
					</q-item-section>
				</q-item>
			</template>
		</q-list>
	</q-drawer>

	<!------------------------------Add Game Xbox------------------------------------>
	<q-dialog v-model="showAddXbox" persistent>
		<q-card class="column q-pa-md"  style="width:750px;max-width: 80vw;">
			<div class="text-h6 q-pb-sm">Add Content</div>
			<div v-if="state.device=='xbox'" class="q-pb-sm">
				Ex : <a href="https://www.xbox.com/en-US/games/store/nier-replicant-ver122474487139/9nmbk4v30xjr" target="_blank">
					https://www.xbox.com/en-US/games/store/nier-replicant-ver122474487139/9nmbk4v30xjr</a> (US Store only)
			</div>
			<div v-else-if="state.device=='steam'" class="q-pb-sm">
				Ex : <a href="https://store.steampowered.com/app/2050650/Resident_Evil_4" target="_blank">
					https://store.steampowered.com/app/2050650/Resident_Evil_4</a> (app only)
			</div>
			<div v-else-if="state.device=='appletv'" class="q-pb-sm">
				Ex : <a href="https://tv.apple.com/th/movie/the-super-mario-bros-movie/umc.cmc.lnitkepcr7q85n755kia38uc" target="_blank">
					https://tv.apple.com/th/movie/the-super-mario-bros-movie/umc.cmc.lnitkepcr7q85n755kia38uc</a> (TH Only)<br>
				Or : <a href="https://itunes.apple.com/th/movie/the-super-mario-bros-movie/id1678836201" target="_blank">
					https://itunes.apple.com/th/movie/the-super-mario-bros-movie/id1678836201</a> (TH Only)
			</div>
			<div>
				<q-input v-model="linkXbox" label="URL" :color="state.color" 
				autofocus outlined dense clearable @keydown.enter="addGame()"/>
				<q-input v-if="state.device=='appletv' && state.user.uid=='1'" v-model="price" label="PRICE" :color="state.color" 
				autofocus outlined dense clearable/>
			</div>
			<div class="q-py-md">
				<q-btn label="Add" :color="state.color" class="q-mr-sm" :loading="loadAdd" @click="addGame()"/>
				<q-btn label="Close" color="red" :loading="loadAdd" v-close-popup />
			</div>
			<div v-if="message!=''" class="q-py-xs">{{message}}</div>
		</q-card>
    </q-dialog>

	<!------------------- Info Card ------------------------>
    <q-dialog v-model="showInfo" >
        <q-card class="q-pa-md" style="width:500px;">
			<q-list>
				<template v-for="(info, index) in infoList" :key="index">
					<q-item dense>
						<q-item-section>
							<q-item-label><b>v. {{info.v}}</b></q-item-label>
						</q-item-section>
						<q-item-section side>
							<q-item-label caption>{{ info.date }}</q-item-label>
						</q-item-section>
					</q-item>
					<q-item dense>
						<template v-for="(text, index2) in info.text" :key="index2">
							- {{ text }}<br>
						</template>
					</q-item>
					<q-separator spaced inset />
				</template>
			</q-list>
        </q-card>
    </q-dialog>

	<!------------------------------Manage Notify------------------------------------>
	<q-dialog v-model="showNotify">
		<q-card class="column q-pa-md"  style="width:400px;max-width: 90vw;">
			<div class="text-h6 q-pb-sm">Manage Authen</div>
			<q-input v-if="key==''||key==null" v-model="username" label="Username" @keydown.enter="getQR()" clearable dense>
				<template v-slot:after>
					<q-btn  round :color="state.color" icon="search" @click="getQR()"/>
				</template>
			</q-input>
			<q-img width="60%" v-if="imgQR!=''" :src="'data:image/svg+xml;base64,'+imgQR"></q-img>
			{{ imgQR!=''?'Key : '+key:'' }}
			{{  (key!=''&&key!=null)&&imgQR==''?'Delete Authen':'' }}
			<div v-if="key!=''&&key!=null" class="row q-py-sm">
				<q-input v-model="code" label="Code" style="width: 120px;" outlined clearable dense></q-input>
				<q-btn label="ok" color="primary" class="q-mx-sm" :loading="loadAdd" @click="manageAuth()"></q-btn>
				<q-btn v-if="imgQR!=''" label="reset" color="red" @click="imgQR='';msgPass='';code='';key='';username=''"></q-btn>
			</div>
			<div v-if="msgPass!=''" class="q-py-xs">{{msgPass}}</div>

			<q-separator class="q-my-md" />

			<div class="text-h6 q-pb-sm">Manage Notify</div>
			<div>คุณสามารถกดแจ้งเตือนได้แค่ 1 เครื่องเท่านั้น</div>
			<div v-if="subJson!='err'" class="q-py-md">
				<q-btn color="deep-orange" @click="manageSub" :loading="loadAdd">{{ chkSub?'subscribe':'unsubscribe'}}</q-btn>
			</div>
			<div v-if="message!=''" class="q-py-xs">{{message}}</div>
		</q-card>
	</q-dialog>

	<!------------------- Login ------------------------>
    <q-dialog v-model="showLogin" >
        <q-card class="q-pa-md" style="width:250px;" align="center">
			<div class="text-h6 q-pb-sm">LOGIN</div>
			<q-btn to="/login" dense flat>
				<q-img src="images/line_login.png" width="120px"></q-img>
			</q-btn>
			<q-separator class="q-my-md" />
			<div>Use Authen App</div>
			<div class="q-gutter-md q-py-md" style="max-width: 200px">
				<q-input v-model="username" label="Username" outlined clearable dense></q-input>
				<q-input v-model="code" label="Code" @keydown.enter="checkAuth()" outlined clearable dense></q-input>
			</div>
			<q-btn label="ok" color="primary" class="q-mx-sm" :loading="loadAdd" @click="checkAuth()"></q-btn>
			<q-btn label="reset" color="red" @click="imgQR='';msgPass='';code='';key='';username=''"></q-btn>
			<div v-if="msgPass!=''" class="q-py-xs">{{msgPass}}</div>
        </q-card>
    </q-dialog>

	<q-dialog v-model="showNews">
        <q-card class="q-pa-md" style="width:400px;">
			<div class="text-h6 text-red q-pb-sm"><q-icon name="campaign"></q-icon> ประกาศ</div>
			<div v-html="newsObj.detail"></div>
			<q-toggle
				dense
				v-model="setNews"
				:color="state.color"
				@click="state.setNews(setNews?newsObj.id:'0')"
				class="q-pr-sm q-py-md"
				label="ไม่ต้องแสดงอีก"
			/>
        </q-card>
    </q-dialog>
      
</template>

<script setup>
	import { ref,getCurrentInstance,onUpdated } from 'vue'
	import { useQuasar } from 'quasar';
	import { useState } from '../stores/webStore';
	import axios from 'axios';
	const state = useState();
	state.getLC();
	let loc = state.loc;
	let inter = getCurrentInstance().appContext.config.globalProperties.$inter;
	let hostUrl = getCurrentInstance().appContext.config.globalProperties.$hostUrl;
	let leftDrawerOpen = ref();
	let theme = ref(loc.theme);
	let view = ref(loc.view);
	let cType = ref(loc.cType);
	let showARS = ref(loc.showARS);
	let rate = ref(loc.rate);
	let currency = ref(loc.currency);
	let store = ref(loc.store);
	let stores = ['th','us','jp','tr'];
	let tmpList = [
		// {icon:'home',label:'Home',link:''},
		{icon:'search',label:'Search',link:'/search'},
		{icon:'local_offer',label:'Deal',link:'/deal'},
		{icon:'save_alt',label:'Recently Add',link:'/recent'},
		{icon:'schedule',label:'Pre-Order',link:'/preorder'},
		{icon:'fiber_new',label:'New Release',link:'/newrelease'},
		{icon:'calendar_month',label:'Calendar',link:'/calendar'},
	];
	let menuList = ref(Array.from(tmpList));
	let menuOther = ref([
		{icon:'mdi-steam',color:'blue-grey-8',label:'Steam',link:'/steam'},
		{icon:'mdi-sony-playstation',color:'blue',label:'Playstation',link:'/playstation'},
		{icon:'mdi-microsoft-xbox',color:'green',label:'XBOX',link:'/xbox'},
		{icon:'mdi-nintendo-switch',color:'red',label:'Switch',link:'/nintendo'},
		{icon:'mdi-apple',color:'grey-8',label:'Apple',link:'/appletv'},
		{icon:'currency_exchange',color:'yellow-9',label:'Exchange',link:'/exchange'},
	]);
	let showAddXbox = ref(false);
	let linkXbox = ref('');
	let price = ref('');
	let message = ref('');
	let loadAdd = ref(false);
	let showInfo = ref(false);
	let infoList = ref([
		{ v:'5.3',date:'06/09/2024',text:['เพิ่มราคา Japan ของ Xbox','เพิ่มปุ่ม All Store ของ Xbox และ Switch']},
		{ v:'5.2',date:'01/09/2024',text:['ไม่แสดงราคาประเทศชิลีของ Switch','แก้ไขตอนกดดูเกม Switch บนประเทศ US']},
		{ v:'5.1',date:'14/01/2024',text:['แก้ไขหน้า Home','เพิ่มปุ่มแสดงราคาอาเจนของ Xbox']},
		{ v:'5.0',date:'21/12/2023',text:['เพิ่ม Library และระบบ Share Library']},
		{ v:'4.9',date:'26/11/2023',text:['เพิ่มเมนู Leaving Soon บน Xbox']},
		{ v:'4.8',date:'19/11/2023',text:['แสดงราคาของประเทศอื่นๆบน Xbox']},
		{ v:'4.7',date:'20/08/2023',text:['เพิ่มปุ่มแสดงราคาอาเจนของ Nintendo']},
		{ v:'4.6',date:'16/08/2023',text:['เพิ่มกราฟแสดงราคาของ Xbox ให้สามารถเปลี่ยนดูประเทศอื่นๆได้']},
		{ v:'4.5',date:'20/07/2023',text:['เพิ่มรายละเอียดราคาอาเจนของ Nintendo','เพิ่มเมนู Play on PC บน XBOX']},
		{ v:'4.4',date:'24/06/2023',text:['เพิ่มรายละเอียดราคาอาเจนของ Nintendo','เพิ่ม Shortcut Diablo point และ Apex Point']},
		{ v:'4.3',date:'08/05/2023',text:['แก้ไขการเพิ่มภาพยนต์บน AppleTV','เพิ่มหมวดหมู่ Preorder บน AppleTV','เพิ่มรายละเอียดต่างๆของภาพยนต์บน AppleTV','ระบุว่าเรื่องนี้ถูกถอดจาก Store']},
		{ v:'4.2',date:'10/04/2023',text:['เพิ่มการแจ้งเตือนข่าวสารในเว็บ','กดดูชื่อประเทศของ Switch ได้','แก้ไขการแสดงผลหน้า Home']},
		{ v:'4.1',date:'03/04/2023',text:['เพิ่มระบบ Authen']},
		{ v:'4.0',date:'02/04/2023',text:['เพิ่มระบบ Nofify','เพิ่มหน้า Home รวมทุก platform','แปลงเว็บเป็น PWA']},
		{ v:'3.1',date:'20/03/2023',text:['เพิ่มปุ่มเพิ่มเกมใน Steam','เพิ่ม Mod Thai ใน Steam','แก้ไขการแสดงผลในหน้ารายละเอียด']},
		{ v:'3.0',date:'18/03/2023',text:['เพิ่ม App Steam','เปลี่ยนที่อยู่เก็บรูปของ Switch','เพิ่ม limit ในการแสดงผลหน้า deal เป็น 2 เท่า']},
		{ v:'2.12',date:'10/02/2023',text:['แก้ไขกราฟแสดงราคาให้สามารถกดดูราคาบนจุดกราฟได้บนมือถือ','เพิ่มกราฟราคาของ Switch','เพิ่มแสดง version ของเว็บและรายละเอียดที่แก้ไข']},
		{ v:'2.11',date:'09/02/2023',text:['เพิ่มเมนู Voucher ของ Switch','แสดง icon voucher บนเกมที่สามารถใช้ได้']},
		{ v:'2.10',date:'03/02/2023',text:['แก้ไขเรทของ VISA เปลี่ยนไปใช้ค่าจากเว็บ visa.co.th โดยตรง']},
		{ v:'2.9',date:'28/01/2023',text:['แก้ไขเรทของ VISA จากเดิมดึงจากค่า ASK มาเป็นค่า BID']},
		{ v:'2.8',date:'16/01/2023',text:['เพิ่มการแสดงผลว่าใช้เรท VISA หรือ Master Card']},
		{ v:'2.7',date:'23/12/2022',text:['เพิ่มการแสดงผลของราคาประเทศอาเจนติน่าบน Switch']},
		{ v:'2.6',date:'22/12/2022',text:['เพิ่ม Export Excel และ Total ในหน้า Library','เพิ่มปุ่มเปิดไปหน้า store microsoft บน XBOX']},
		{ v:'2.5',date:'17/12/2022',text:['เพิ่มตัวเลือก Master Card และ VISA Card']},
		{ v:'2.4',date:'22/11/2022',text:['เพิ่มเมนู Lowest Price ของ AppleTV']},
		{ v:'2.3',date:'28/10/2022',text:['เพิ่มเมนู Subscribtion ของ Switch']},
		{ v:'2.2',date:'15/10/2022',text:['เพิ่มค้นหาจากผู้พัฒนาในหน้าแสดงรายละเอียด']},
		{ v:'2.1',date:'02/10/2022',text:['เพิ่ม EA Price สำหรับราคา XBOX','ปรับกราฟราคาของ AppleTV']},
		{ v:'2.0',date:'20/09/2022',text:['รวม Apple , Playstation , Xbox , Switch และ Exchange เข้าด้วยกัน','เพิ่มระบบสมาชิกของทุกๆเครื่อง']},
	]);
	let iconInfo = ref('o_info');
	let showNotify = ref(false);
	let chkSub = ref(false);
	let subJson = ref('');
	let username = ref('');
	let msgPass = ref('');
	let imgQR = ref('');
	let key = ref('');
	let code = ref('');
	let showLogin = ref(false);
	let showNews = ref(false);
	let newsObj = ref('');
	let setNews = ref(false);

	if ('serviceWorker' in navigator) {
		addEventListener('load', async () => {
			await navigator.serviceWorker.register('./sw.js');
		});
	}

	onUpdated(async() => {

		// let title = state.device!='home'?state.device.toUpperCase()+" Thailand Price Chart":'Price';
		// let desc = "Check Price of content on "+state.device.toUpperCase()+" Thailand";
		
		state.updateTitle();

		menuList.value = Array.from(tmpList);
        if(state.device == 'xbox'){
			menuList.value.push({icon:'sports_esports',label:'Game Pass',link:'/gamepass'})
			menuList.value.push({icon:'mdi-emoticon-cry-outline',label:'Leaving Soon',link:'/leaving'})
			menuList.value.push({icon:'card_membership',label:'Subscription',link:'/subscription'});
			menuList.value.push({icon:'mdi-desktop-tower-monitor',label:'Play on PC',link:'/pc'});
		} else if(state.device == 'nintendo'){
			menuList.value.push({icon:'card_membership',label:'Subscription',link:'/subscription'});
		} else if(state.device == 'appletv'){
			menuList.value.push({icon:'mdi-bookshelf',label:'Bundle',link:'/bundle'});
			menuList.value.push({icon:'trending_down',label:'Lowest',link:'/lowest'});
		}
    })

	// dialogNews();

	if(localStorage.getItem("user") != "undefined" && localStorage.getItem("user") != null){
		login();
	}

	const q = useQuasar();
		
	function toggleDarkMode() {
		q.dark.toggle();
		state.changeTheme();
	}

	function screenWidth () {
		let x = screen.width;
		switch (true) {
			case (x < 450): return 'small'
			case (x < 1024): return 'medium'
			case (x >= 1024): return 'large'
		}
		return 'large'
	}

	function changeStore(){
		state.changeStore(store.value,currency.value);
		window.location.href = '/store/'+store.value+'/'+currency.value;
	}

	function changeLib(lib){
		state.changeLib(lib);
		window.location.href = '/'+state.device+'/library';
	}

	async function addGame(){
		if(!linkXbox.value) linkXbox.value = '';
		if(linkXbox.value == '') return;
		loadAdd.value = true;
		let form = {
            'link': linkXbox.value,
            'device': state.device,
			'price': price.value,
        }
        await axios.post(hostUrl+'api/addGame', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                if(response.data.data.type == 'error'){
					message.value = response.data.data;
				} else {
					linkXbox.value = '';
					message.value = response.data.data;
				}
            }
        })
        .catch(function (error) {
            console.log(error);
        });
		loadAdd.value = false;
	}

	async function login(){
		
		let user = {
            'uid': state.user.uid,
            'sub': state.user.sub,
            'picture': state.user.picture,
            'store': state.store,
        }
        await axios.post(hostUrl+'api/login', JSON.stringify(user))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                state.setUser(response.data.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
	}

	async function openNotify(){
		showNotify.value = true;
		message.value = '';
		msgPass.value = '';
		imgQR.value = '';
		username.value = '';
		let obj = {};
		await axios.post(hostUrl+'api/getNoti')
		.then(function (response) {
			if(!response.data){
				alert("ERROR Please contact admin")
			}else{
				obj = response.data.data;
				key.value = obj.key;
			}
		})
		.catch(function (error) {
			console.log(error);
		});

		await subscribe();
		if(subJson.value=='err'){
			message.value = 'Browser ของท่านไม่ support';
		} else {
			if(obj.noti == null) {
				chkSub.value = true;
			} else {
				if(obj.noti == subJson.value){
					chkSub.value = false;
					message.value = "คุณทำการลงทะเบียนแจ้งเตือนที่เครื่องนี้อยู่";
				} else {
					chkSub.value = true;
					message.value = "คุณทำการลงทะเบียนแจ้งเตือนที่เครื่องอื่นอยู่ ถ้าคุณลงทะเบียนที่เครื่องนี้ ระบบจะทำการยกเลิกการแจ้งเตือนที่เครื่องอื่น"
				}
			}
		}
		
	}

	async function subscribe(){
		try{
			subJson.value = '';
			let sw = await navigator.serviceWorker.ready;
			let push = await sw.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey:
					'BMXEdB0YklJyXJjsJp-s-8A30arBLYEIfXMTpYD3AZ260D7li1t4skYYZilIDtGK17rrXRWWnzCmpjKJkCEwVys'
			});
			subJson.value = JSON.stringify(push);
		} catch(err) {
			console.log(err.message);
			subJson.value = 'err';
		}
	}

	async function manageSub(){
		loadAdd.value = true;
		message.value = '';
		let sub = chkSub.value?"'"+subJson.value+"'":null;
		let form = {
            'noti': sub,
        }
        await axios.post(hostUrl+'api/manageSub', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                if(response.data.data.type == 'error'){
					message.value = response.data.data;
				} else {
					if(chkSub.value){
						chkSub.value = false;
						message.value = "ลงทะเบียนแจ้งเตือนเรียบร้อยแล้ว";
					} else {
						chkSub.value = true;
						message.value = "ยกเลิกการแจ้งเตือนเรียบร้อยแล้ว";
					}
				}
            }
        })
        .catch(function (error) {
            console.log(error);
        });
		loadAdd.value = false;
	}

	async function getQR(){
		if(!username.value || username.value == ''){
			msgPass.value = 'กรุณากรอก Username';
			return;
		}
		loadAdd.value = true;
		msgPass.value = '';
		imgQR.value = '';
		key.value = '';
		let form = {
            'username': username.value,
        }
        await axios.post(hostUrl+'api/getQR', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                if(response.data.data.type == 'error'){
					msgPass.value = response.data.data;
				} else {
					let result = response.data.data;
					if(result.check){
						msgPass.value = 'Username นี้มีคนใช้แล้ว';
					} else {
						imgQR.value = btoa(result.qr);
						key.value = result.key;
					}
					
				}
            }
        })
        .catch(function (error) {
            console.log(error);
        });
		loadAdd.value = false;
	}

	async function manageAuth(){
		loadAdd.value = true;
		let form = {
			'username': username.value,
            'key': key.value,
			'code': code.value,
        }
        await axios.post(hostUrl+'api/manageAuth', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                if(response.data.data.type == 'error'){
					msgPass.value = response.data.data;
				} else {
					if(response.data.data){
						if(imgQR.value!=''){
							code.value = '';
							imgQR.value = '';
							username.value = '';
							msgPass.value = "เพิ่ม Authen เรียบร้อยแล้ว";
						} else {
							code.value = '';
							key.value = '';
							username.value = '';
							msgPass.value = "ลบ Authen เรียบร้อยแล้ว";
						}
						
					}else{
						msgPass.value = "รหัสไม่ถูกต้อง";
					}
				}
            }
        })
        .catch(function (error) {
            console.log(error);
        });
		loadAdd.value = false;
	}

	async function checkAuth(){
		if(!username.value || username.value == ''){
			msgPass.value = 'กรุณากรอก Username';
			return;
		}
		if(!code.value || code.value == ''){
			msgPass.value = 'กรุณากรอก Code';
			return;
		}
		loadAdd.value = true;
		msgPass.value = '';
		let form = {
			'username': username.value,
			'code': code.value,
        }
        await axios.post(hostUrl+'api/checkAuth', JSON.stringify(form))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                if(response.data.data.type == 'error'){
					msgPass.value = response.data.data;
				} else {
					let result = response.data.data;
					if(result.check){
						state.setUser(result.user);
						location.reload();
					}else{
						msgPass.value = result.msg;
					}
				}
            }
        })
        .catch(function (error) {
            console.log(error);
        });
		loadAdd.value = false;
	}

	// async function dialogNews(){
	// 	newsObj.value = '';
    //     await axios.post(hostUrl+'api/getNews')
    //     .then(function (response) {
    //         if(!response.data){
    //             alert("ERROR Please contact admin")
    //         }else{
    //             if(response.data.data.type == 'error'){
	// 				msgPass.value = response.data.data;
	// 			} else {
	// 				newsObj.value = response.data.data;
	// 				if(newsObj.value.status=='E' && newsObj.value.id!=loc.news) {
	// 					showNews.value = false; //true
	// 					setNews.value = false;
	// 				} else if(newsObj.value.status=='E' && newsObj.value.id==loc.news) {
	// 					setNews.value = true;
	// 				}
	// 			}
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
	// }

</script>